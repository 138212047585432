import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('simple_player', (currentSettings, uiTemplate, shownDuration, overridePlayerRatio) => ({
  localSettings: currentSettings,
  uiTemplate: uiTemplate,
  shownDuration: shownDuration,
  overridePlayerRatio: overridePlayerRatio,
  simplePlayerEl: null,
  player: null,
  defaultSettings: {
    autostart: false,
    aspectratio: '16:9'
  },
  playing: false,
  muted: true,
  duration: 0,
  position: 0,
  progress: 0,
  enableCustomUI: false,
  enabledUITemplate: {},
  showCustomUI: true,
  uiTimeoutState: null,
  loading: true,
  init() {
    
    if(this.localSettings.videoId === undefined || this.localSettings.videoId === null) {
      console.error('Video ID is not set');
      return;
    }

    this.simplePlayerEl = this.$el;

    this.defaultSettings.playlist = "https://cdn.jwplayer.com/v2/media/" + this.localSettings.videoId;
    
    delete this.localSettings.videoId;

    this.defaultSettings = {...this.defaultSettings, ...this.localSettings}

    if(this.$refs.player_div === undefined) {
      console.error('Player div not set');
      return;
    }

    this.player = jwplayer(this.$refs.player_div).setup(this.defaultSettings);

    this.player.on("ready", (e) => {
      this.loading = false;
    })

    if(this.uiTemplate != 'false') {
      
      this.setupUI();
    
    } else {

      if(this.shownDuration) {
        this.player.on('time', (e) => {
          if(e.position > this.shownDuration) {
            this.player.seek(0)
          }
        });
      }
    }

  },
  setupUI() {
    
    const uiTemplates = {
      video_story: {
        bottom: true,
        ui: ['play','mute','progress','caption'],
        dynamic: false,
      },
      episode: {
        top: true,
        ui: ['backBtn'],
        dynamic: 2000,
      }
    }

    if(!(this.uiTemplate in uiTemplates)) {
      console.error('UI template not found');
      return;
    }

    this.enableCustomUI = true;
    this.enabledUITemplate = uiTemplates[this.uiTemplate];

    if(this.enableUIComponent('play') || this.enabledUITemplate.dynamic !== false) {
      this.player.on('play', (e) => {
        this.playing = true;
        if(this.enabledUITemplate.dynamic !== false) {
          this.showCustomUI = false;
        }
      });
      this.player.on('pause', (e) => { 
        this.playing = false;
        if(this.enabledUITemplate.dynamic !== false) {
          this.showCustomUI = true;
        }
      });
    }

    if(this.enableUIComponent('mute')) {
      this.player.on('mute', (e) => {
        this.muted = e.mute;
      });

      this.player.on('unmute', (e) => {
        this.muted = e.mute;
      });

      let context = this;
      window.addEventListener('user_mute', (e) => {
        if(e.detail.mute) {
          context.mute(context);
        }else {
          context.unmute(context);
        }
      });
    }

    this.player.on('meta', (e) => {
      if(e.metadataType === "media") {
        this.duration = e.duration;
      }
    });

    if(this.enableUIComponent('progress')) {
      this.player.on('time', (e) => {

        this.position = e.position;

        if(this.duration !== 0) {
          this.progress = (this.position / this.duration) * 100;
        }
      });
    }

    if(this.enabledUITemplate.dynamic !== false) {
      let context = this;
      this.simplePlayerEl.addEventListener('mousemove', function (e) {
        context.showCustomUI = true;

        if(this.uiTimeoutState !== null) {
          clearTimeout(this.uiTimeoutState)
        }
        this.uiTimeoutState = setTimeout(() => {
          context.showCustomUI = false;
        }, context.enabledUITemplate.dynamic);
      })
    }
    
  },
  enableUIComponent(uiName) {
    return this.enabledUITemplate.ui ? this.enabledUITemplate.ui.indexOf(uiName) !== -1 : false;
  },
  play(context = this) {
    if(context.player) {
      context.player.play();
    }
  },
  pause(context = this) {
    if (context.player) {
      context.player.pause();
    }
  },
  mute(context = this) {
    if(context.player) {
      context.player.setMute(true);
    }
  },
  unmute(context = this) {
    if(context.player) {
      context.player.setMute(false);
    }
  },
  isMuted(context = this) {
    if(context.player) {
      context.muted = context.player.getMute();
    }
    return context.muted;
  },
  toggleAction(action) {
    const context = this;
    const actions = {
      play: { 0: context.pause, 1: context.play, variable: context.playing, trigger: false },
      mute: { 0: context.unmute, 1: context.mute, variable: context.muted, trigger: context.userToggleMute }
    }

    const currentAction = actions[action];
    
    let variableValue = (typeof currentAction.variable === "function") ? currentAction.variable.call(context) : currentAction.variable;

    if(variableValue) {
      currentAction[0].call(context)
    } else {
      currentAction[1].call(context)
    }

    if(currentAction.trigger !== false) {
      currentAction.trigger.call(context)
    }
  },
  userToggleMute(context = this) {
    window.dispatchEvent(new CustomEvent('user_mute', { detail: { mute: context.muted }, bubbles: true }));
  },
  getPositionInSeconds() {
    let h = 0;
    let m = 0;
    let s = 0;
    let num = this.position;
    if (num > 3600) {
      h = Math.floor(num / 3600);
      num -= h * 3600;
    }
    m = Math.floor(num / 60);
    num -= m * 60;
    s = Math.floor(num);
    return `${ h > 0 ? h + ':' : '' }${ (m + '').padStart(h > 0 ? 2 : 1, '0') }:${ (s + '').padStart(2, '0') }`;
  },
}));